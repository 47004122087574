import { Link } from "@remix-run/react";
import {RepoListItem, RepoResult} from './repo-list-item';
import {spaceRepoName} from './utils';

export const RemixRepoListItem = ({repoResult}: {repoResult: RepoResult}) => (
  <RepoListItem repoResult={repoResult}>
    <Link to={`/gh/${repoResult.repo_name}`} className="text-lg">
      {spaceRepoName(repoResult.repo_name)}
    </Link>
  </RepoListItem>
);