import UpdatedAt from './updatedAt';
import Description from './description';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';

// Used in both web app and chrome extension - test both!

export interface RepoResult {
  repo_name: string;
  watch_count: number;
  description?: string;
  pushedAt?: string;
  stargazerCount?: number;
}

export function RepoListItem(
  {repoResult, children}: {repoResult: RepoResult, children: React.ReactNode}) {
  const starCount: number = repoResult.stargazerCount ?? repoResult.watch_count;

  return (
    <li className="py-3 hover:bg-gray-50 dark:hover:bg-gray-700">
      <div className="flex items-center">
        {children}
        <a href={`https://github.com/${repoResult.repo_name}`} target="_blank" className="ml-1 tooltip inline-flex items-center" data-tip="View on GitHub" aria-label={`View "${repoResult.repo_name}" on GitHub`}>
          <ArrowTopRightOnSquareIcon className="inline-block size-4 text-gray-400" />
        </a>
      </div>
      {/* TODO: description (font size 16) (https://github.com/collections/github-browser-extensions) */}
      {/* TODO: fix nan, None */}
      {(repoResult.description !== 'None' && repoResult.description !== 'nan') && 
      <div className="py-1 text-sm text-rr-medium">
        <span><Description description={repoResult.description} maxLength={137} /></span>
      </div>
      }
      <div className="text-xs text-rr-light">
        <span className="mr-2">☆{starCount.toLocaleString()}</span>
        <UpdatedAt updatedAt={repoResult.pushedAt} />
      </div>
    </li>
  );
}