import {DateTime} from 'luxon';

function getRelativeTimeString(updatedAt: string): string {
  const dtNow = DateTime.now();
  const dtUpdatedAt = DateTime.fromISO(updatedAt);

  const diffObject = dtNow.diff(dtUpdatedAt, ['years', 'months', 'days']).toObject();

  if (diffObject.years && diffObject.years >= 2) {
    return `${diffObject.years} years ago`;
  }
  else if (diffObject.years && diffObject.years === 1) {
    return 'last year';
  }
  else if (diffObject.months && diffObject.months >= 2) {
    return `${diffObject.months} months ago`;
  }
  else if (diffObject.months && diffObject.months === 1) {
    return 'last month';
  }
  else if (diffObject.days && diffObject.days >= 21) {
    return '3 weeks ago';
  }
  else if (diffObject.days && diffObject.days >= 14) {
    return '2 weeks ago';
  }
  else if (diffObject.days && diffObject.days >= 7) {
    return 'last week';
  }

  return 'this week'
}

export default function UpdatedAt({updatedAt}: {updatedAt: string | undefined}) {
  if(!updatedAt || updatedAt === 'nan') {
    return null;
  }

  const relativeTime = getRelativeTimeString(updatedAt);
  // no tooltip if updated this week
  const formattedTime = relativeTime === 'this week' ? null : DateTime.fromISO(updatedAt).toLocaleString(DateTime.DATE_MED);

  return <span className="tooltip" data-tip={formattedTime}>Updated {relativeTime}</span>
}