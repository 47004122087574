
// Remove all GH emojis (eg. DopplerHQ/awesome-interview-questions)
// TODO: create mapping to unicode chars based off of `https://api.github.com/emojis`
// may not support some non-unicode GH specific ones like "octocat"
// https://github.com/ikatyang/emoji-cheat-sheet/blob/master/README.md
const emojiRegex = /:\w+:/gi;
const defaultMaxLength = 137; // from github repo list view

export default function Description({description, maxLength}: {description: string | undefined, maxLength?: number}) {
  if(!description || description === 'nan') {
    return null;
  }

  description = description.replaceAll(emojiRegex, '');

  if(maxLength === undefined) {
    maxLength = defaultMaxLength;
  }

  if(description.length > maxLength) {
    description = `${description.slice(0, maxLength)}…`;
  }

  return <>{description}</>
}